const MOBILE_IMAGE_BREAK_POINT = 769

export default {
  computed: {
    gatewayProvider() {
      throw new Error('must override `gatewayProvider in component`')
    },

    shippingType() {
      throw new Error('must override `shippingType in component`')
    },

    expressMapParams() {
      return this.$store.getters['orderShipments/expressMapParams']
    },

    expressMapApiBase() {
      return this.$store.getters['orderShipments/expressMapApiBase']
    },

    expressMapUrl() {
      let url = ''
      for (let key in this.expressMapParams) {
        url += `${key}=${this.expressMapParams[key]}&`
      }

      return this.expressMapApiBase + '?' + url
    },

    isMobile() {
      // workaround for safari can't get window width from vuex at first time loading
      return (
        (this.$store.getters['windowWidth'] || window.innerWidth) <
        MOBILE_IMAGE_BREAK_POINT
      )
    },

    isWebview() {
      // https://developers.whatismybrowser.com/useragents/explore/software_name/facebook-app/
      // Facebook App on iOS 15.1
      // 'Mozilla/5.0 (iPhone; CPU iPhone OS 15_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/19B74 [FBAN/FBIOS;FBDV/iPhone12,1;FBMD/iPhone;FBSN/iOS;FBSV/15.1;FBSS/2;FBID/phone;FBLC/en_US;FBOP/5;FBIA/FBIOS]'
      let userAgent = navigator.userAgent
      let rules = [
        'WebView',
        '(iPhone|iPod|iPad)(?!.*Safari/)',
        'Android.*(wv|.0.0.0)'
      ]
      let regex = new RegExp(`(${rules.join('|')})`, 'ig')
      return Boolean(userAgent.match(regex))
    },

    usingJdWindowOpenMap() {
      return this.gatewayProvider === 'jd' && !this.isWebview
    }
  },

  methods: {
    convertMapInfo(data) {
      return {
        cvs_address: data['CVSAddress'] || data['StoreAdd'],
        cvs_store_id: data['CVSStoreID'] || data['StoreNumber'],
        cvs_store_name: data['CVSStoreName'] || data['StoreName'],
        cvs_telephone: data['CVSTelephone'],
        logistics_sub_type: data['LogisticsSubType'],
        gateway: data['gateway'],
        extra_data: data['ExtraData']
      }
    },

    openExpressMap(expressMapUrl, callbacks = []) {
      if (this.usingJdWindowOpenMap) {
        this.openJdMap(expressMapUrl, callbacks)
      } else {
        Turbolinks.visit(expressMapUrl)
      }
    },

    openJdMap(expressMapUrl, callbacks = []) {
      let iWidth = 500
      let iHeight = 450
      let iTop = (window.screen.availHeight - 30 - iHeight) / 2
      let iLeft = (window.screen.availWidth - 10 - iWidth) / 2
      let windowOpenConfig =
        `height=${iHeight}, innerHeight=${iHeight}, width=${iWidth}, innerWidth=${iWidth}, top=${iTop}, left=${iLeft}, ` +
        'status=no, location=no, status=no,menubar=no, toolbar=no, resizable=no, scrollbars=yes'

      let processJdMapData = (event) => {
        if (
          !event.origin.match(
            /https:\/\/(familytest|family).orders.ilohas.info/
          )
        )
          return

        let data = JSON.parse(event.data)
        let extraInfo = {
          ExtraData: this.shippingType,
          gateway: this.gatewayProvider
        }

        this.syncStoreInfoToFrom({ ...data, ...extraInfo })
        callbacks.forEach((callback) => callback())

        window.removeEventListener('message', processJdMapData, false)
      }

      window.open(expressMapUrl, 'MapSelect', windowOpenConfig)

      window.addEventListener('message', processJdMapData, false)
    },

    clearQueryString() {
      let cleanUri = window.location.origin + window.location.pathname

      window.history.replaceState({}, document.title, cleanUri)
    }
  }
}
