import CountryCallingCodes from '@services/country_calling_codes.json'
import { computed, ref } from 'vue'

/**
 * return searchCallingCodes, countryCallingCodes
 * @returns {{searchCallingCodes: string, countryCallingCodes: (function(): ComputedRef<array>)}}
 */
export const useCallingCodes = () => {
  const searchCallingCodes = ref('')

  const countryCallingCodes = computed(() => {
    if (searchCallingCodes.value.length > 0) {
      return CountryCallingCodes.filter(
        (code) =>
          code.name
            .toLowerCase()
            .indexOf(searchCallingCodes.value.toLowerCase()) >= 0 ||
          code.dial_code
            .toLowerCase()
            .indexOf(searchCallingCodes.value.toLowerCase()) >= 0
      )
    } else {
      return CountryCallingCodes
    }
  })

  return {
    searchCallingCodes,
    countryCallingCodes
  }
}

export default useCallingCodes
